import { Component, Input, OnInit } from '@angular/core';
import {
  AppointmentRecordingFragment,
  GetAppointmentRecordingQueryService,
} from '../graphql/appointment-recording.onelife.generated';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'omg-healthscribe-banner',
  templateUrl: './healthscribe-banner.component.html',
  styleUrls: ['./healthscribe-banner.component.scss'],
})
export class HealthscribeBannerComponent implements OnInit {
  @Input() appointmentId: number | undefined;
  appointmentRecording$: Observable<AppointmentRecordingFragment | null>;

  constructor(
    private getAppointmentRecordingQueryService: GetAppointmentRecordingQueryService,
  ) {}

  ngOnInit(): void {
    if (this.appointmentId) {
      this.appointmentRecording$ = this.getAppointmentRecordingQueryService
        .fetch({
          id: this.appointmentId.toString(),
        })
        .pipe(map(result => result.data.appointment?.recording || null));
    }
  }
}
